import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {addEditCliente, delCliente, getClientes, rateCliente} from '../../services';
import {ICliente, IClienteFilter, IClienteRes, IClienteState} from "../types/ICliente";
import historyHelper from "../../helpers/history";
import {AppState} from "./rootReducer";

const initialState: IClienteState = {
    status: "idle"
}

export const mostrarClientes = createAsyncThunk(
    'mostrarClientes', async (params:IClienteFilter|undefined, thunkAPI ) => {
        const {clientes} = thunkAPI.getState() as AppState;
        let paramsFilter = params || clientes.filtered;

        const response = await getClientes(paramsFilter)
        return {data: response.data as IClienteRes, params: response.config.params}
    }
)

export const agregarEditarCliente = createAsyncThunk(
    'Clientes/agregarEditar', async (params: { cliente: ICliente, crearPrestamo?: boolean }, thunkAPI) => {
        const {cliente, crearPrestamo} = params;
        const response = await addEditCliente(cliente);
        // if (response.status === 200 || response.status === 204) {
        //     if(!!cliente.id) {
        //         historyHelper.replace('/clientes', {cliente: {id: cliente.id}})
        //     }else if(crearPrestamo){
        //         historyHelper.replace('/crear/prestamo', { cliente: { clienteId: response.data.id, fullName: cliente.nombres + " " + cliente.apellidos }})
        //     }
        //     // else (!!cliente.id){
        //     //     historyHelper.replace('/clientes', { cliente: {id:cliente.id} })
        //     // }
        // };
        return {data: response.data, edit:!!cliente.id};
    }
);
export const mostrarClienteById = createAsyncThunk(
    'mostrarClienteById', async (id:number,thunkAPI) => {
        // let byId = (thunkAPI.getState() as AppState)?.clientes?.list?.items?.find(c=>c.id === id);
        // if(byId){
        //     return {data: byId}
        // }

        const response = await getClientes(id);
        return {data: response.data as ICliente}
    }
)
export const editarClienteRating = createAsyncThunk(
    'editarClienteRating', async (params: { id: number, rate: number }) => {
        await rateCliente(params);
        return {data: params};
    }
);
export const eliminarCliente = createAsyncThunk(
    'cliente/eliminar', async (id: number) => {
        const response = await delCliente(id);
        if (response.status === 200 || response.status === 204) historyHelper.back();
        return id;
    }
);


const slice = createSlice({
    name: 'mostrarClientes',
    initialState,
    reducers: {
    },
    extraReducers: builder => {
        //--mostrarClientes
        builder.addCase(mostrarClientes.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarClientes.fulfilled, (state,action ) => {
            state.status = "idle";
            state.filtered = action.payload.params;
            state.list = action.payload.data;
        })
        builder.addCase(mostrarClientes.rejected, (state ) => {
            state.status = "idle";
        })

        //--agregarEditarCliente
        builder.addCase(agregarEditarCliente.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(agregarEditarCliente.fulfilled, (state,action ) => {
            state.status = "idle";
            if(state.list){
                state.list.items = state.list?.items.filter(e => e.id !== action.payload.data.id);
                state.list.items.unshift(action.payload.data);
                if(!action.payload.edit){
                    ++state.list.pageSize
                    ++state.list.totalCount
                }

            }
        })
        builder.addCase(agregarEditarCliente.rejected, (state ) => {
            state.status = "idle";
        })
        //--mostrarClienteById
        builder.addCase(mostrarClienteById.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarClienteById.fulfilled, (state,action ) => {
            state.status = "idle";
            state.byId = action.payload.data;
        })
        builder.addCase(mostrarClienteById.rejected, (state ) => {
            state.status = "idle";
        })
        //--editarClienteRating
        builder.addCase(editarClienteRating.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(editarClienteRating.fulfilled, (state,action ) => {
            state.status = "idle";
            const { id,rate:calificacion } = action.payload.data;
            let list = state.list;
            state.byId = {...state.byId,calificacion};
            if(list?.items?.length){
                list.items = list.items.map(c=>{
                    if(c.id === id){
                        return {...c, calificacion}
                    }
                    return c
                })
            }
            state.list = list;



        })
        builder.addCase(editarClienteRating.rejected, (state ) => {
            state.status = "idle";
        })
        //--eliminarCliente
        builder.addCase(eliminarCliente.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(eliminarCliente.fulfilled, (state,action ) => {
            state.status = "idle";
            const id = action.payload;
            if(state.list?.items){
                state.list.items = state.list?.items.filter(e => e.id !== id)
            }
        })
        builder.addCase(eliminarCliente.rejected, (state ) => {
            state.status = "idle";
        })
    }
});

export default slice.reducer;
