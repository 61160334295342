
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import {getEnums} from '../../services';
import {IAppState} from "../types/Icommon";
import {AppState} from "./rootReducer";
import {mostrarCarteras} from "./carterasReducers";


export interface IEnumsState extends IAppState{
    list?: any
}
const initialState: IEnumsState = {
    status: "idle",
    list: []
}

export const mostrarEnums = createAsyncThunk(
    'mostrarEnums', async () => {
        const response = await getEnums()
        return {data: response.data}
    }
)

export const mostrarEnumsSinoEsta = createAsyncThunk(
    'mostrarEnumsSinoEsta', async (_,thunkAPI) => {
        let enums = (thunkAPI.getState() as AppState)?.enums;
        if (Object.keys(enums?.list).length || enums.status === "loading") return false;

        return thunkAPI.dispatch(mostrarEnums());

    }
)





const slice = createSlice({
    name: 'enums',
    initialState,
    reducers: {
        setEnums: (state,{payload}) => {
            state.list = payload
        }
    },
    extraReducers: builder => {
        //---mostrarUsosMultiples
        builder.addCase(mostrarEnums.pending, (state ) => {
            state.status = "loading";
        })
        builder.addCase(mostrarEnums.fulfilled, (state,action ) => {
            state.status = "idle";
            const { data } = action.payload

            state.list = data;
        })
        builder.addCase(mostrarEnums.rejected, (state ) => {
            state.status = "idle";
        })
    }

});

export const {setEnums} = slice.actions;
export default slice.reducer;
